<!-- Informes APDS -->

<template>
  <div class="frasReportF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>


        <!-- Botonera -->
        <div class="pt-2 pl-2">
          <baseBtraExtra class="conflex"
            :permExtra="permExtra"
            :modulo="btExtCfg"
            @onEvent="execAccion">
          </baseBtraExtra>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:950px">
          <div class="conflex">
            <div class="columna" style="width:32%">

              <!-- Menú -->
              <div class="cab">Tipos de Informes</div>
              <v-sheet v-bind="$cfg.styles.marco">
                <v-treeview
                  :items="recordsRead[0]"
                  item-key="d"
                  item-text="n"
                  item-children="c"
                  activatable
                  dense
                  open-on-click
                  return-object
                  @update:active="changeInf">
                </v-treeview>
              </v-sheet>
            </div>

            <div class="columna" style="width:66%;margin-left:10px">
              <div class="cab">Condiciones</div>

              <v-sheet v-bind="$cfg.styles.marco">
                <!-- Ordenado -->
                <div style="display:flex">
                    <v-select
                        v-bind="$select"
                        v-model="ct.ord[2]"
                        :label="ct.ord[1]"
                        :items="ord"
                        item-value="d"
                        item-text="n">
                    </v-select>
                </div>

                <!-- Registro, Serie Fras. Venta -->
                <div style="display:flex">
                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.reg[2]"
                        :label="ct.reg[1]"
                        :items="recordsRead[2]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                    <v-select
                        style="flex: 0 0 35%"
                        v-bind="$select"
                        v-model="ct.ser[2]"
                        :label="ct.ser[1]"
                        :items="recordsRead[3]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                    <v-select
                        style="flex: 0 0 15%"
                        v-bind="$select"
                        v-model="ct.iva[2]"
                        :label="ct.iva[1]"
                        :items="recordsRead[5]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                </div>

                <!-- Estado, Iva, Tipo/situacion -->
                <div style="display:flex">
                    <v-select
                        style="flex: 0 0 70%"
                        v-bind="$select"
                        v-model="ct.estado[2]"
                        :label="ct.estado[1]"
                        :items="recordsRead[4]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                    <v-select
                        style="flex: 0 0 30%"
                        v-bind="$select"
                        v-model="ct.sit[2]"
                        :label="ct.sit[1]"
                        :items="recordsRead[7]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                </div>

                <!-- Periodo Fechas -->
                <div style="display:flex">
                  <uiMultiFilterFechas
                    style="width:100%"
                    :items="recordsRead[6]"
                    :ct="ct"
                    :ctrl="ct.fecha"
                    tipo="periodo"
                    >
                  </uiMultiFilterFechas>
                </div>

                <!-- Tipo Cuenta / Cuenta -->
                <div style="display:flex">

                    <uiMultiple
                      style="flex: 0 0 70%"
                      :ctrl="ct.cta_id"
                      :ct="ct"
                      @change="changeCta">
                    </uiMultiple>

                    <v-select
                        style="flex: 0 0 30%"
                        v-bind="$select"
                        v-model="ct.cuenta[2]"
                        :label="ct.cuenta[1]"
                        :items="recordsRead[8]"
                        item-value="d"
                        item-text="n">
                    </v-select>
                </div>

                <!-- Central, Grupo -->
                <div style="display:flex">

                    <ctrlF style="flex: 0 0 60%" :ct="ct.cen"></ctrlF>

                    <v-select
                        style="flex: 0 0 40%"
                        v-bind="$select"
                        v-model="ct.grupo[2]"
                        :label="ct.grupo[1]"
                        :items="recordsRead[9]"
                        item-value="d"
                        item-text="n"
                        multiple>
                        <template v-slot:selection="{ index }">
                            <span
                            v-if="index=== 0"
                            class="grey--text caption">
                                ({{ ct.grupo[2].length }} grupo/s seleccionado/s)
                            </span>
                        </template>
                    </v-select>

                </div>

                <!-- Producto -->
                <div style="display:flex">
                    <ctrlF style="flex: 0 0 100%" :ct="ct.pro"></ctrlF>
                </div>

                <!-- Jefe zona (COS) -->
                <div style="display:flex">
                    <v-select
                        v-bind="$select"
                        v-model="ct.zona[2]"
                        :label="ct.zona[1]"
                        :items="itemsZonas"
                        item-value="id"
                        item-text="name"
                        :disabled="zona>0">
                    </v-select>
                </div>

                <!-- Jefe zona (COS) -->
                <div style="display:flex">

                  <uiText
                    :label="ct.exped[1]"
                    v-model="ct.exped[2]">
                  </uiText>
                </div>

              </v-sheet>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>


<script>

  import { mixR } from "@/mixins/mixR.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";

  export default {
    mixins: [mixR],
    components: { baseHeader, baseBtraExtra },
    props: {},

    data() {
      return {
        stIni: {
          api: "pagosR",
          titulo:"·Informe PAGOS",
          name:"pagosR",
          mView:'',
          pView:[]
        },

        ord: [],
        itemsTipos:[],
        itemsZonas:[],

      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.btExtCfg.btnsAccion= [
          { accion:'verPdf', btn:"pdf" },
          { accion:'verExcel', btn:"excel" }

        ];

        //Tipos Empresa
        this.itemsTipos= this.$store.state.datos_iniciales.tipo_empresa.slice();
        this.itemsTipos.unshift({id:'0',name:'( Selecciona Tipo )'});
        // coordinadores de servicio
        this.itemsZonas= this.$store.state.datos_iniciales.zonas.slice();
        this.itemsZonas.unshift({id:'0',name:'( Selecciona )'});

        //omision
        this.ct.reg[2]='0';

      },

        // si cambia cuenta en uimulti
        changeCta(evt) {
            console.log('changeCta',evt);
            //if (!evt[4].comp) return;
        },


      //verPdf,verExcel,showReport en mixR

    }
  };
</script>
